<mat-toolbar color="primary">
  <a routerLink="/" routerLinkActive="active">Planning Poker</a>
  <span class="spacer"></span>
  <a
    mat-icon-button
    aria-label="help"
    href="https://en.wikipedia.org/wiki/Planning_poker"
    target="_blank"
  >
    <mat-icon>help</mat-icon>
  </a>
  <a
    mat-icon-button
    aria-label="code"
    href="https://github.com/blueur/planning-poker"
    target="_blank"
  >
    <mat-icon>code</mat-icon>
  </a>
</mat-toolbar>
<router-outlet></router-outlet>
